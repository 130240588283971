




















import { Component, Vue } from "vue-property-decorator";
import User from "@/app/domains/settings/models/user.model";

@Component({
  async beforeRouteUpdate(to, from, next): Promise<void> {
    const instance = this as UsersView;
    if (to.name === "settings-users" && instance.users.length) {
      const [firstUser] = instance.users;
      next({
        name: instance.userRoute,
        params: { userId: firstUser.id },
      });
    } else {
      next();
    }
  },
})
export default class UsersView extends Vue {
  public get users(): Array<User> {
    return this.$store.getters["settingsModule/users"];
  }

  private goToFirstUser(): void {
    const [firstUser] = this.users;
    if (firstUser) {
      this.$router.push({
        name: this.userRoute,
        params: { userId: firstUser.id },
      });
    } else this.$router.push({ name: "settings-users" });
  }

  private deleteUser(user: User): void {
    this.$store.commit(
      "settingsModule/setUsers",
      this.users.filter((u) => u.id !== user.id)
    );
    this.goToFirstUser();
  }

  async created(): Promise<void> {
    const users = (await this.$store.dispatch("settingsModule/getUsers")).data;
    this.$store.commit("settingsModule/setUsers", users);

    if (this.$route.params.userId) return;

    const [firstUser] = this.users;

    // return if same userId selected
    if (this.$route.params.userId === firstUser.id) return;

    this.$router.push({
      name: this.userRoute,
      params: { userId: firstUser.id },
    });
  }

  private userRoute = "settings-user-selected";
}
